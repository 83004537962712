/*--------------------------------

LineIcons Free Web Font
Author: lineicons.com

-------------------------------- */

@font-face {
  font-family: "LineIcons";
  src: url("../fonts/LineIcons/LineIcons.eot") format("embedded-opentype"), url("../fonts/LineIcons/LineIcons.woff2") format("woff2"),
    url("../fonts/LineIcons/LineIcons.woff") format("woff"), url("../fonts/LineIcons/LineIcons.ttf") format("truetype"),
    url("../fonts/LineIcons/LineIcons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/
.lni {
  display: inline-block;
  font: normal normal normal 1em/1 "LineIcons";
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.lni-sm {
  font-size: 0.8em;
}
.lni-lg {
  font-size: 1.2em;
}
/* absolute units */
.lni-16 {
  font-size: 16px;
}
.lni-32 {
  font-size: 32px;
}
/*------------------------
	icons
-------------------------*/
.lni-chevron-up::before {
  content: "\ea5e";
}
.lni-facebook-filled::before {
  content: "\eab2";
}
.lni-twitter-original::before {
  content: "\ebd7";
}
.lni-instagram::before {
  content: "\eaf3";
}
.lni-linkedin-original::before {
  content: "\eb14";
}
.lni-youtube::before {
  content: "\ebfd";
}
.lni-facebook-original::before {
  content: "\eab4";
}
.lni-google::before {
  content: "\eb04";
}
.lni-pinterest::before {
  content: "\eb4d";
}
.lni-layout::before {
  content: "\eb07";
}
.lni-inbox::before {
  content: "\eaec";
}
.lni-heart::before {
  content: "\eae1";
}
.lni-grid-alt::before {
  content: "\ead5";
}
.lni-bug::before {
  content: "\ea41";
}
.lni-play::before {
  content: "\eb51";
}
